import { useState } from "react";
import RequestApi from "../../../../../../Request-api/RequestApi";
import SelectCustom from "../../../../../../UI/SelectCustom/SelectCustom";
import TextFieldCustom from "../../../../../../UI/TextFieldCustom/TextFieldCustom";
import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import useNationality from "../../../../../../Utils/useNationality";
import useProvince from "../../../../../../Utils/useProvince";
import Swal from "sweetalert2";

const EditLocation = () => {
    const nacionalidades = useNationality();
    const provincias = useProvince();
    const history = useHistory();
    const { idEDNS, location } = useSelector(state => state.authLogin);
    const [state, setState] = useState({
        nacionalidad: location.nacionalidad,
        provincia: location.provincia,
        ciudad: location.ciudad,
        direccion: location.direccion,
        codPostal: location.codPostal,
        piso: '',
        depto: ''
    });

    const handleOnChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        });
    }

    const handleConfirmar = () => {
        RequestApi.putProfile({
            "domDireccion": state.direccion,
            "nacionalidad": state.nacionalidad,
            "provincia": state.provincia,
            "ciudad": state.ciudad,
            "codigoPostal": state.codPostal,
        }, idEDNS)
        .then(()=>{
            Swal.fire({
                icon: 'success',
                title: 'Información actualizada',
                text: 'Tús datos fueron modificados correctamente.',
                willClose: () => {
                    history.goBack();
                }
            })
        })
    }

    const handleBack = () => {
        history.goBack()
    }



    return (
        <>
            <Container>
                <Grid container>
                    <Grid item xs={12} mb={3}>
                        <Button onClick={handleBack}>Volver</Button>
                        <Typography>Cambiar domicilio</Typography>
                    </Grid> 
                    <Grid item xs={12} sm={6} md={4}>
                        <SelectCustom
                                label="Nacionalidad"
                                placeholder="Seleccionar"
                                options={nacionalidades?.map((n) => {
                                return { name: n.nacionalidadDesc, value: n.nacionalidadPk };
                                })}
                                fullWidth
                                name="nacionalidad"
                                onChange={handleOnChange}
                                value={state.nacionalidad}
                            ></SelectCustom>
                            <SelectCustom
                                label="Provincia"
                                placeholder="Seleccionar"
                                options={provincias?.map((n) => {
                                return { name: n.nombreProvincia, value: n.idProvincia };
                                })}
                                fullWidth
                                name="provincia"
                                onChange={handleOnChange}
                                value={state.provincia}
                            ></SelectCustom>

                            <p className="textInputs-register">Ciudad</p>
                            <TextFieldCustom
                                onChange={handleOnChange}
                                placeholder="Ingresá tu Ciudad"
                                name="ciudad"
                                value={state.ciudad}
                            ></TextFieldCustom>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div>
                            <p className="textInputs-register">Dirección (Calle y Número)</p>
                            <TextFieldCustom
                                onChange={handleOnChange}
                                placeholder="Ingresá tu Dirección"
                                name='direccion'
                                value={state.direccion}
                            ></TextFieldCustom>
                        </div>
                        <div>
                            <p className="textInputs-register">Código Postal</p>
                            <TextFieldCustom
                                onChange={handleOnChange}
                                placeholder="Ingresá tu Código Postal"
                                name='codPostal'
                                value={state.codPostal}
                            ></TextFieldCustom>
                        </div>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <div>
                        <p className="textInputs-register">
                            Piso (Opcional)
                        </p>
                        <TextFieldCustom
                            onChange={handleOnChange}
                            placeholder="Ingresá tu Piso"
                            name='piso'
                            value={state.piso}
                        ></TextFieldCustom>
                        </div>
                        <div>
                            <p className="textInputs-register">
                                Depto (Opcional)
                            </p>
                            <TextFieldCustom
                                onChange={handleOnChange}
                                placeholder="Ingresá tu Depto"
                                name='depto'
                                value={state.depto}
                            ></TextFieldCustom>
                            </div>
                    </Grid>
                    <Grid item mt={3}>
                        <Button variant="outlined" color="error" onClick={handleConfirmar}>Confirmar</Button>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
export default EditLocation;