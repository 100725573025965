import { Button, Container, Grid, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import RequestApi from "../../../../../../Request-api/RequestApi";
import useOS from "../../../../../../Utils/useOS";
import ToolTipOS from "../../../../../Register/Forms/DatosGenerales/ToolTIp/ToolTip";
import SelectCustom from "../../../../../../UI/SelectCustom/SelectCustom";
import TextFieldCustom from "../../../../../../UI/TextFieldCustom/TextFieldCustom";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

const EditPlan = () => {
    const planes = useOS();
    const [tipoPlanes, setTipoPlanes] = useState([])
    const { plan, idEDNS } = useSelector(state => state.authLogin);
    const { planPk, gamaPk } = plan;
    const [state, setState] = useState({
        planPk: planPk,
        gamaPk: gamaPk,
        otro_plan: null,
        otro_gama: null
    });
    const history = useHistory();

    const handleOnChange = (e) => {
        if(e.target.name === 'planPk') {
            setState({
                planPk: e.target.value,
                gamaPk: ''
            })
        } else {
            setState({
                ...state,
                [e.target.name]: e.target.value
            })
        }
    }

    const handleBack = () => {
        history.goBack()
    }

    const handleConfirmar = () => {
        RequestApi.putProfile({
            planPk: state.planPk,
            gamaPk: state.gamaPk,
            planEspecial: state.otro_plan,
            gamaEspecial: state.otro_gama
          }, idEDNS)
        .then(r => {
            if(r.estado === 0) {
                Swal.fire({
                    icon: 'success',
                    title: 'Información actualizada',
                    text: 'Tús datos fueron modificados correctamente.',
                    willClose: () => {
                        history.goBack();
                    }
                })
            }
        })
    }

    useEffect(() => {
        RequestApi.getTipoPlan(state.planPk)
        .then(r => {
            setTipoPlanes(r);
        })
    }, [state.planPk])

    return (
        <>
            <Container>
                <Grid container>
                    <Grid item xs={12} mb={3}>
                        <Button onClick={handleBack}>Volver</Button>
                        <Typography>Cambiar mi plan</Typography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <SelectCustom
                            placeholder="Seleccionar"
                            icon={<ToolTipOS />}
                            label='Obra Social / Prepaga'
                            value={state.planPk}
                            options={planes?.map((p) => {
                            return { name: p.planDesc, value: p.planPk };
                            })}
                            onChange={handleOnChange}
                            name="planPk"
                        ></SelectCustom>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        {state.planPk === (-1) ? (
                            <>
                                <p className="textInputs-register">¿Cuál?</p>
                                <TextFieldCustom
                                    onChange={handleOnChange}
                                    placeholder="Ingresá tu obra social"
                                    name="otro_plan"
                                ></TextFieldCustom>
                                <p className="textInputs-register">Tipo de plan</p>
                                <TextFieldCustom
                                    onChange={handleOnChange}
                                    placeholder="Ingresá tu tipo de plan"
                                    name="otro_gama"
                                ></TextFieldCustom>
                            </>
                        ) : (
                            <SelectCustom
                                placeholder="Seleccionar"
                                icon={<ToolTipOS />}
                                label="Tipo de plan"
                                value={state.gamaPk}
                                options={tipoPlanes?.map((p) => {
                                return { name: p.gamaDesc, value: p.gamaPk };
                                })}
                                onChange={handleOnChange}
                                disable={!tipoPlanes.length}
                                name="gamaPk"
                            ></SelectCustom>
                        )}

                    </Grid>
                    <Grid item mt={3}>
                        <Button variant="outlined" color="error" onClick={handleConfirmar}>Confirmar</Button>
                    </Grid>

                </Grid>
            </Container>
        </>
    )
}
export default EditPlan;