import Navbar from "../../componentes/Navbar/Navbar";
import HeaderHome from "../../componentes/HeaderHome/HeaderHome";
import { Box, Container } from "@mui/material";
import Novedades from "./components/Novedades/Novedades";
import CentrosMedicos from "./components/CentrosMedicos/CentrosMedicos";
import Welltest from "./components/Welltest/Welltest";
import Footer from "../../componentes/Footer/Footer";
import { useEffect, useState } from 'react';
import PopUp from "./components/PopUps/PopUp";


const Landing = () => {
    const toDay = new Date();
    const popupDate = toDay.toLocaleDateString("en-GB", {timeZone: "America/Argentina/Buenos_Aires"});
    const showIn = ['18/09/2023', '19/09/2023', '20/09/2023', '21/09/2023'];
    const [open, setOpen] = useState(showIn.includes(popupDate));

    /* Scroll Top */
    useEffect(() => {
        (window.scrollTo(0, 0))
    }, []);

    return (
        <>
            <PopUp open={open} handleClose={() => setOpen(false)} />
            <Navbar />
            <Box sx={{marginTop: '74px'}}>
                <HeaderHome />
                <Container sx={{ paddingX: { xs: 0 }}}>
                    <Novedades />
                    <CentrosMedicos />
                    <Welltest />
                </Container>
            </Box>
            <Footer />
        </>
    )
}

export default Landing;