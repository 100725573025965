import { useEffect, useState } from "react";
import { Button, Container, Divider, FormControl, Grid, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import ReactPinField from "react-pin-field"
import ButtonCustom from "../../../../../../UI/ButtonCustom/ButtonCustom"
import RequestApi from '../../../../../../Request-api/RequestApi';
import TextFieldCustom from '../../../../../../UI/TextFieldCustom/TextFieldCustom';
import Swal from "sweetalert2";

const EditEmail = () => {
    const {email, tel, idEDNS, idPersona, docNum, docType, nombre, apellido} = useSelector(state => state.authLogin)
    const history = useHistory();
    const [step, setStep] = useState(1)
    const [ volverAintentar, setVolverAintentar] = useState(false)
    const [code, useCode] = useState("");
    const [error, setError] = useState() 
    const [time, setTime] = useState({
        hours: parseInt(0),
        minutes: parseInt(1),
        seconds: parseInt(59)
    });
    const [state, setState] = useState({
        tel: tel,
        email: email
    });

    const next = () => {
        setStep(step + 1)
    }

    const handleOnChange = (e) => {
        setState({
            ...state,
            [e.target.name]: e.target.value
        })
    }

    const handleBack = () => {
        history.goBack()
    }

    const handleChTel = () => {
        RequestApi.putProfile({
            telefono1: state.tel
        }, idEDNS)
        .then(r => {
            if(r.estado === 0) {
                handleBack();
            } else {
                //error
            }
        })
        .catch(err => {
            //error
        })
    }

    const handleChEmail = () => {
        RequestApi.sendPinMail({
            "tipoDocumento": docType.id,
            "nroDocumento": docNum,
            "email": state.email,
            "nombre": nombre,
            "apellido": apellido
        }).then(r => {
            next()
        })
    }

    const handleConfirmar = () => {
        RequestApi.checkPinMail({
            "tipoDocumento": docType.id,
            "nroDocumento": docNum,
            "pin": code
            }).then(r => {
            if(r.estado === 0){
                RequestApi.putEmail(idPersona, idEDNS, state.email)
                .then(r => {
                    if(r.estado === 0) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Información actualizada',
                            text: 'Tús datos fueron modificados correctamente.',
                            willClose: () => {
                                history.goBack();
                            }
                        })
                    }
                })
            } else if(r.estado === 5){
                setError(r.mensaje)
            } 
        })
    }

    const tick = () => {
        if (time.hours === 0 && time.minutes === 0 && time.seconds === 0)
          setVolverAintentar(true);
        else if (time.minutes === 0 && time.seconds === 0)
          setTime({
            hours: time.hours - 1,
            minutes: 59,
            seconds: 59
          });
        else if (time.seconds === 0)
          setTime({
            hours: time.hours,
            minutes: time.minutes - 1,
            seconds: 59
          });
        else
          setTime({
            hours: time.hours,
            minutes: time.minutes,
            seconds: time.seconds - 1
          });
      };

    const handleReenviar = () => {
        setVolverAintentar(false)
        setTime({
         hours: parseInt(0),
         minutes: parseInt(1),
         seconds: parseInt(59)
       });
       RequestApi.sendPinMail({
         "tipoDocumento": docType,
         "nroDocumento": docNum,
         "email":state.email,
         "nombre": nombre,
         "apellido": apellido
       }).then(r => {
         console.log(r)
       })
     }

    useEffect(() => {
        let timerID = setInterval(() => tick(), 1000);
        return () => clearInterval(timerID);
    });

    return (
        <>
            <Container>
                {step === 1 ? (
                    <Grid container>
                        <Grid item xs={12} mb={3}>
                            <Button onClick={handleBack}>Volver</Button>
                            <Typography>Modificar contacto</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <div>
                                <p className="textInputs-register">Email</p>
                                <TextFieldCustom
                                    onChange={handleOnChange}
                                    placeholder="Ingresá tu email"
                                    name='email'
                                    value={state.email}
                                ></TextFieldCustom>
                                <Button variant="outlined" color="error" onClick={handleChEmail}>Confirmar</Button>
                            </div>
                            <Divider sx={{my:5}}/>
                            <div>
                                <p className="textInputs-register">Teléfono</p>
                                <TextFieldCustom
                                    onChange={handleOnChange}
                                    placeholder="Ingresá tu teléfono"
                                    name='tel'
                                    value={state.tel}
                                ></TextFieldCustom>
                                <Button variant="outlined" color="error" onClick={handleChTel}>Confirmar</Button>
                            </div>
                        </Grid>
                    </Grid>
                ) : (
                    <FormControl fullWidth>
                        <p className="text-title"  style={{maxWidth: '400px'}}>
                            Te enviamos un e-mail a<b> {state.email} </b>con el código de confirmación.
                            Ingresalo para continuar. 
                        </p>
                        <p className="textInputs-step2">Ingresá el código</p>
                        <ReactPinField
                            onChange={useCode}
                            length={6}
                            secret
                            style={{
                            caretColor: "transparent",
                            width: 38,
                            height: 50,
                            borderRadius: 6,
                            border: "2px solid #bdb3c6",
                            outline: "none",
                            textAlign: "center",
                            margin: 4,
                            fontSize:"20px"
                            }}
                        />
                        {error&&<p style={{display:"flex", color:"red"}} >{error}</p>}
                        <div style={{display:"flex"}}>
                        {volverAintentar ? <p onClick={handleReenviar} style={{textDecoration:"underline red", color:"red"}}>Reenviar</p>: 
                        <div style={{display:"flex"}}>
                        <p  className="text-title-cod-step2">
                            ¿Todavía no lo recibiste? Volver a enviar el código en
                        </p>     
                        <p className="text-time-step2">
                            {`${time.minutes.toString().padStart(2, "0")}
                            :${time.seconds.toString().padStart(2, "0")}`}
                        </p>
                        </div>}
                        </div>
                        <div className= "alert-step2"  style={{maxWidth: '400px'}}>
                            <p className="text-mail-step2" >Recordá revisar también tu bandeja de correo no deseado. Tenés 20 minutos para activarlo,
                                delo contrario tendrás que registrarte nuevamente. 
                            </p>
                        </div>
                        <div style={{ paddingTop: "40px", paddingBottom: "40px", maxWidth: '500px'}}>
                                <ButtonCustom width="40%" onClick={handleConfirmar} name="Continuar" />
                        </div>
                    </FormControl>
                )}
                
            </Container>
        </>
    )
}
export default EditEmail;