import { Button, Container, Grid, TextField, Typography } from "@mui/material";
import RequestApi from '../../../../../../Request-api/RequestApi';
import { useSelector } from "react-redux";
import { useState } from "react";
import { useRef } from "react";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";


const EditPassword = () => {
    const history = useHistory();
    const [password, setPassword] = useState({
        oldPass:'',
        newPass:''
    });
    const [error, setError] = useState('');
    const [errorPass, setErrorPass] = useState('');
    const repPass = useRef();

    const {idPersona} = useSelector(state => state.authLogin);

    const handleOnChange = (e) => {
        setPassword({
            ...password,
            [e.target.name] : e.target.value,
        });
    }

    const handleConfirmar = () => {
        if(repPass.current.value === password.newPass) {
            RequestApi.chPassword(idPersona, password.newPass, password.oldPass)
            .then(r => {
                if(r.estado === 0) {
                    setError('');
                    setErrorPass('');
                    Swal.fire({
                        icon: 'success',
                        title: 'Información actualizada',
                        text: 'Tús datos fueron modificados correctamente.',
                        willClose: () => {
                            history.goBack();
                        }
                    })
                } else {
                    setErrorPass('La contraseña ingresada es incorrecta')
                }
            })
        } else {
            setError('La contraseña ingresada no coincide')
        }
    }

    const handleBack = () => {
        history.goBack()
    }

    return (
        <>
            <Container>                
                <Grid container>
                    <Grid item xs={12} mb={3}>
                        <Button onClick={handleBack}>Volver</Button>
                        <Typography>Cambiar contraseña</Typography>
                    </Grid>
                    <Grid item xs={12} md={6} mb={2}>
                            <TextField label='Contraseña actual' fullWidth sx={{maxWidth: {md: '90%'}}} onChange={handleOnChange} name='oldPass' error={errorPass} helperText={errorPass} type='password'/>
                    </Grid>
                    <Grid item xs={12} md={6} mb={2}>
                            <TextField label='Nueva contraseña' fullWidth sx={{maxWidth: {md: '90%'}}} onChange={handleOnChange} name='newPass' type='password'/>
                    </Grid>
                    <Grid item xs={12} md={6} mb={2}>
                            <TextField label='Repetir contraseña' fullWidth sx={{maxWidth: {md: '90%'}}} inputRef={repPass} error={error} helperText={error} type='password'/>
                    </Grid>
                    <Grid item mt={3}>
                        <Button variant="outlined" color="error" onClick={handleConfirmar}>Confirmar</Button>
                    </Grid>
                </Grid>
            </Container>
        </>
    )
}
export default EditPassword;