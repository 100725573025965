import { combineReducers } from "redux";
import clientes from "./clientes";
import filters from "./filtersReducer";
import authLogin from './authLoginReducer'
import grupoFamiliar from './grupoFamiliarReducer'
import signinModal from './signinModalReducer'
import estudiosReducer from "./estudiosReducer"


export default combineReducers({
  clientes,
  filters,
  authLogin,
  grupoFamiliar,
  signinModal,
  estudiosReducer
});
