import { Avatar, Box, Divider, Grid, List, ListItem } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useSelector } from "react-redux";
import ItemMenu from "./ItemMenu/ItemMenu";
import CustomizedButtons from "../../../../../UI/ButtonCustom/ButtonCustom";
import { useHistory } from "react-router-dom";
import Breadcrumb from "../../../../../componentes/Breadcrumb/Breadcrumb";

const paths = [
    {
        title: 'Contraseña',
        path: 'perfil/editar/password',
        icon: 'Password'
    },
    {
        title: 'Domicilio',
        path: 'perfil/editar/location',
        icon: 'Location'
    },
    {
        title: 'Email/Tel',
        path: 'perfil/editar/email',
        icon: 'Email'
    },
    {
        title: 'Mi Plan',
        path: 'perfil/editar/plan',
        icon: 'Plan'
    }
]

const Perfil = () => {
    const {nombre, apellido, fechaNac, email, tel, location, plan} = useSelector(state => state.authLogin);
    const history = useHistory();
    var month_diff = Date.now() - new Date(fechaNac);
    var age_dt = new Date(month_diff); 
    var year = age_dt.getUTCFullYear();
    var age = Math.abs(year - 1970);
    const planName = plan?.name?.trim() || "";
    const planGama = plan?.gama?.trim() || "";

    return (
        <>
            <Grid container sx={{mt: '2rem'}} >
                <Box sx={{padding: '1rem'}}>
                    <Breadcrumb location='Perfil' />
                </Box>
                <Grid item xs={12} md={2} display='flex' alignItems='center' justifyContent='center'>
                <Avatar
                alt="Me"
                sx={{ width: 112, height: 112, bgcolor: '#fff', color:'#e1e1e1', my: 1}}
                >
                    <AccountCircleIcon sx={{ width: '100%', height: '100%', border: 0 }}/>
                </Avatar>
                </Grid>
                <Grid item xs={12} md={5} display='flex' alignItems='center'>
                    <List>
                        <ListItem>Nombre y apellido: {nombre} {apellido}</ListItem>
                        <ListItem>Edad: {age}</ListItem>
                        <ListItem>Dirección: {location.direccion}</ListItem>
                    </List>
                </Grid>
                <Grid item xs={12} md={5} display='flex' alignItems='center'>
                    <List>
                        <ListItem>Teléfono: {tel}</ListItem>
                        <ListItem>Email: {email}</ListItem>
                        <ListItem>Mi Plan: {`${planName} - ${planGama}`}</ListItem>
                    </List>
                </Grid>
                <Grid item xs={12}>
                    <Divider sx={{my: '2rem'}}>Modificar datos</Divider>
                </Grid>
                {paths.map(i => {
                    return <ItemMenu icon={i.icon} path={i.path} name={i.title}/>
                })}
            </Grid>
        </>
    )
}

export default Perfil;