import { useEffect, useState } from "react";
import RequestApi from '../Request-api/RequestApi'

const useOS = () => {
    const [OS, setOS] = useState([])

    useEffect(() => {

        if(OS.length === 0){
            RequestApi.getPlanes()
            .then(res => {
                setOS(res)
            });
        }

    })

    return OS;
}

export default useOS;