import { useState } from "react";
import { Grid, IconButton, Paper, Box, Typography } from "@mui/material";
import PasswordIcon from '@mui/icons-material/Password';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import EmailIcon from '@mui/icons-material/Email';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import HealthAndSafetyIcon from '@mui/icons-material/HealthAndSafety';
import { useHistory } from "react-router-dom";
import ModalAlertValidarCuenta from "../../Validacion/Steps/ModalAlertValidarCuenta/ModalAlertValidarCuenta";


const ItemMenu = ({icon, name, path}) => {
    const history = useHistory();
    const [open, setOpen] = useState(false);


    const goTo = (path) => {
            history.push(`/pacientes/${path}`);
    }

    const getIcon = () => {
        switch(icon) {
            case 'Password':
                return <PasswordIcon sx={{fontSize:'3rem'}}/>
            case 'Location':
                return <LocationOnIcon sx={{fontSize:'3rem'}}/>
            case 'Email':
                return <EmailIcon sx={{fontSize:'3rem'}}/>
            case 'Products':
                return <BusinessCenterIcon sx={{fontSize:'3rem'}}/>
            case 'Plan':
                return <HealthAndSafetyIcon sx={{fontSize:'3rem'}}/>
            default:
                return null
        }
    }


    return (
        <>
            <Grid item xs={6} md={3} display='flex' justifyContent='center'>
                <Box width='200px' m={2}>
                        <Paper elevation={1} sx={{borderRadius: '30px', backgroundColor:'#FCFCFC', minHeight:'135px'}} >
                            <IconButton aria-label="Example" sx={{width: '100%', aspectRatio:'1 / 1', flexDirection:'column'}} onClick={() => goTo(path)}>                
                                    {getIcon()}
                                    <Typography pt='1rem'  px='.4rem' fontWeight='bold' fontSize='1rem' sx={{fontFamily: "'Lato', sans-serif"}}>{name}</Typography>
                            </IconButton>
                        </Paper>
                </Box>
            </Grid>
        </>
    )
}
export default ItemMenu;